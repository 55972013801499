import React from 'react';

import events from '../../data/events';

import Event from '../../components/Event';
import Layout from '../../components/Layout';


const EventsPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <h2>Events</h2>
      <Event event={events['advantage-of-a-setting-sun']} />
      <Event event={events['metaculture-reading-room']} />
      <Event event={events['bibliotheca-24']} />
    </Layout>
  );
};

export default EventsPage;
