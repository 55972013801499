import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import { parseDate } from '../utils/datetime';
import isPast from 'date-fns/is_past';

import MEDIA_QUERY from '../constants/media-queries';


const EventWrapper = styled.div`
  border: 1px solid black;
  padding: 0.5em;
  margin-bottom: 0.5em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  ${MEDIA_QUERY.S} {
    flex-direction: row;
  }

  color: black;

  &:hover {
    background: black;
    color: white;
  }
`

class Event extends Component {
  render() {
    const { title, location, date, slug } = this.props.event; 
    const parsedDate = parseDate(date);
    const isInPast = isPast(parsedDate);
    let extraWrapperStyle = {};
    
    if (isInPast) {
      extraWrapperStyle = { 
        color: '#CCCCCC',
        borderColor: '#CCCCCC'
      };
    }

    return (
      <Link to={`/events/${slug}`} style={{ textDecoration: 'none' }}>
        <EventWrapper style={extraWrapperStyle}>
          <div>
            {title}
          </div>
          <div>
            {date}
            &nbsp;
            {location}
          </div>
        </EventWrapper>
      </Link>
    );
  }
}

export default Event;
